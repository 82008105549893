import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
  },
  // 企业概况
  {
    path: "/enterprise",
    name: "enterprise",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/enterprise/index.vue"),
  },
  // 产业运营
  {
    path: "/industry",
    name: "industry",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/industry/index.vue"),
  },
  // 企业文化
  {
    path: "/culture",
    name: "culture",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/culture/index.vue"),
  },
  // 关注聚益科
  {
    path: "/follow",
    name: "follow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/follow/index.vue"),
  },
  {
    path: "/followDetail",
    name: "followDetail",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/follow/followDetiles.vue"),
  },
  // 聚首聚益科
  {
    path: "/meet",
    name: "meet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/meet/index.vue"),
  },
  // 人才机制
  {
    path: "/personnel",
    name: "personnel",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/personnel/index.vue"),
  },
  // 多彩聚益科
  {
    path: "/colourful",
    name: "colourful",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/colourful/index.vue"),
  },
  //详情页
  {
    path: "/details",
    name: "details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/colourful/Details.vue"),
  },
  {
    path: "/listdetails",
    name: "listdetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/colourful/listDetails.vue"),
  },
  // 员工风采
  {
    path: "/staff",
    name: "staff",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/staff/index.vue"),
  },
    // 员工风采-更多
    {
    path: "/staffMore",
    name: "staffMore",
    component: () => import(/* webpackChunkName: "about" */ "../views/staff/more.vue"),
    },
  // 加入我们
  {
    path: "/join",
    name: "join",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/join/index.vue"),
  },
    // 自定义菜单
  {
    path: "/template/:id/:from?",
    name: "template",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/template/index"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
