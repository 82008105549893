<template>
<!--  <div id="nav">-->
    <div id="zrjkLogo" ></div>
    <div class="wrap" id="wrap">
      <div class="leftNav" id='leftNav' v-if="paramFrom===''">
        <div class="con">
          <router-link to="/" class="logo">
            <em></em>
            <img class="index_logo" src="http://www.juyike.cn/Public/Home/images/logo2020.png" />
          </router-link>
          <ul class="menu menuBox" id="menuBox">
            <li class="active_lis" v-for="(item,index) in listTabledata" :key="index">
              <router-link :class="routePath === item.path ? 'actives' : ''" :to="item.path" v-if="item.parentId == 0" ><em></em>{{item.titleName}}</router-link>
              <ol  v-if="item.children">
                <li v-for="(item, key) in (item.children || [])" :key="key"><router-link :class="routePath === item.path ? 'actives' : ''" :to="item.path">{{item.titleName}}</router-link></li>
              </ol>
            </li>
          </ul>
        </div>
        <div class="footer">聚益科集团版权所有<br><a href="https://beian.miit.gov.cn" target="_blank">京ICP备17051076号-1</a>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052776" style="display:inline-block;text-decoration:none;">京公网安备 11010502052776号
            <img src="./assets/jingchatubiao.png" style="float:left;height:20px; width: 20px;"/></a>
        </div>
      </div>
    </div>
    <router-view />
    <ul class="sidebarNav">
      <li>
        <em></em>
        <span v-if="weixinPic && weixinPic.length > 0">
          <img :src="getImgUrl(weixinPic[0].picture)" />
        </span>
      </li>
      <li>
        <em></em>
        <span>
          总机: 010-50916999
          <br />
          传真: 010-50916990
        </span>
      </li>
      <li>
        <em></em>
        <span>
          <a href="https://map.baidu.com/?shareurl=1&poiShareUid=c0e7ff39976a1f9dae31e547">
            <img src="http://www.juyike.cn/Public/Home/images/address.jpg" />
          </a>
        </span>
      </li>
      <li>
        <em></em>
        <span>
          <div v-for="(item) in (appList || [])">
            <h3>{{ item.orName }}</h3>
            <img :src="getImgUrl(item.picture)" height="136"/>
          </div>
          <!-- <div>
            <h3>源动力Android版</h3>
            <img :src="AndroidPic" />
          </div> -->
        </span>
      </li>
    </ul>
<!--  </div>-->
</template>
<script>
import { list_url_list, firstRlUrl, getImgUrl } from '@/api/index.js';
let timer = null;
export default {
  data() {
    return {
      listTabledata:[],
      routePath: '',
        weixinPic: '',
        AndroidPic: '',
        iosPic:'',
        appList: [],
      paramFrom: '',
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$nextTick(function () {
      const wrap = document.getElementById('wrap')
      const zrjkLogo = document.getElementById('zrjkLogo')
      const leftNav = document.getElementById('leftNav')
      if (location.hash !== '#/') {
          document.body.style.overflow = 'auto';
        if(wrap){
          wrap.style.background= "#ffffff";
          zrjkLogo.setAttribute('class', '')
            this.routePath = location.hash.substr(1)
        }
      } else {
          document.documentElement.scrollTop = 0;
          document.body.style.overflow = 'hidden';
        if(wrap){
          wrap.style.background = "url(http://www.juyike.cn/Public/Home/images/homeBg.jpg) no-repeat center 0";
          zrjkLogo.setAttribute('class', 'zrjkLogo')
          leftNav.setAttribute('class', 'leftNav leftNavAnimation')
          clearTimeout(timer)
          timer = setTimeout(() => {
            console.log('zrjkLogo');
            const app = document.getElementById('app')
            app.removeChild(zrjkLogo);
          }, 5000)
        }
      }
      const menuDiv = document.getElementById('menuBox');
      menuDiv.style.overflowY = "auto";
      if(window.screen.height === 1080){
        menuDiv.style.height = parseInt(window.screen.height/1.5) +'px';
      } else {
        menuDiv.style.height = parseInt(window.screen.height/1.7) +'px';
      }
    })

  },

  watch: {
    '$route.path': function (newVal) {
      const wrap = document.getElementById('wrap')
      if (this.$route.path.indexOf('/template') > -1) {
        this.paramFrom = this.$route.params.from || '';
      }
      if (newVal !== '/') {
          document.body.style.overflow = 'auto';
        if(wrap){
          wrap.style.background= "#ffffff";
            this.routePath = location.hash.substr(1);

        }
      } else {
          document.documentElement.scrollTop = 0;
          document.body.style.overflow = 'hidden';
        if(wrap){
          wrap.style.background = "url(http://www.juyike.cn/Public/Home/images/homeBg.jpg) no-repeat center 0";
        }
        setTimeout(window.location.reload(),3000);
      }
    }
  },
  methods: {
    // 获取菜单栏数据
    getData() {
      list_url_list().then(res=> {
        if(res && res.data && res.data.length > 0 ) {
          sessionStorage.setItem("menuList", JSON.stringify(res.data));
          this.listTabledata = res.data
        }
      })
      .catch(error=>{
          console.log(error)
      });
      firstRlUrl().then(res=> {
            if(res && res.data ) {
                this.weixinPic = res.data.weixin;
                this.appList = res.data.app;
            }
        })
            .catch(error=>{
                console.log(error)
            });
    },
      getImgUrl(url) {
          return getImgUrl(url)
      },
  },
  computed: {},
};
</script>



<style lang="less" >
  body{height:100%; overflow: hidden;}

  /*滚动条样式*/
  .menuBox::-webkit-scrollbar {
    display:none;
    /*width: 4px;*/
    /*height: 4px;*/
  }
  .menuBox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
  }
  .menuBox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: rgba(0,0,0,0.1);

  }
  .wrap{
    background:url(./assets/homeBg.jpg) no-repeat center 0;
    height:100%;
    width:100%;
    position: absolute;
    background-attachment:fixed;
    background-size: cover;
    -webkit-background-size: cover;/* 鍏煎Webkit鍐呮牳娴忚鍣ㄥChrome鍜孲afari */
    -o-background-size: cover;/* 鍏煎Opera */
    zoom: 1; }
  .leftNav{
    width:12.5%;
    min-width:150px;
    background:#3c3c3c;
    position: fixed;
    top:0;
    left:auto;
    z-index: 55;
  }
  .leftNavAnimation{
    -webkit-animation:leftNav .7s 3s ease both;-moz-animation:leftNav .7s 3s ease both;animation:leftNav .7s 3s ease both
  }
  @keyframes leftNav{
    0%{left:-12.5%;}
    100%{left:0;}
  }
  .rightCon{
    width:87.5%;
    min-width:1050px;
    left:12.5%;
    position:absolute;
    top:0;
  }

  .rightCon .main{position: absolute; z-index: 15;top:0; width:100%;height: 80%}
  .rightCon .animent{height:100%;}
  .rightCon .animent li{width:33%; float: left;height: 100%;background:url(./assets/homelist.jpg) no-repeat right 0;padding-top:7%;}
  .rightCon .animent li:nth-of-type(1){
    -webkit-animation:gozr 2s 4s ease both;
    -moz-animation:gozr 2s 4s ease both;
    animation:gozr 2s 4s ease both
  }
  @-webkit-keyframes gozr{
    0%{opacity:0;}
    100%{opacity:1;}
  }
  @-moz-keyframes gozr{
    0%{opacity:0;}
    100%{opacity:1;}
  }
  @keyframes gozr{
    0%{opacity:0;}
    100%{opacity:1;}
  }

  .rightCon .animent li:nth-of-type(2){
    -webkit-animation:gzzr 2s 5s ease both;
    -moz-animation:gzzr 2s 5s ease both;
    animation:gzzr 2s 5s ease both
  }
  @-webkit-keyframes gzzr{
    0%{opacity:0;}
    50%{opacity:0.5;}
    100%{opacity:1;}
  }
  @-moz-keyframes gzzr{
    0%{opacity:0;}
    50%{opacity:0.5;}
    100%{opacity:1;}
  }
  @keyframes gzzr{
    0%{opacity:0;}
    50%{opacity:0.5;}
    100%{opacity:1;}
  }

  .rightCon .animent li:nth-of-type(3){
    background:none;
    -webkit-animation:jszr 2s 6s ease both;
    -moz-animation:jszr 2s 6s ease both;
    animation:jszr 2s 6s ease both;
    width:34%;
  }
  @-webkit-keyframes jszr{
    0%{opacity:0;}
    50%{opacity:0.5;}
    100%{opacity:1;}
  }
  @-moz-keyframes jszr{
    0%{opacity:0;}
    50%{opacity:0.5;}
    100%{opacity:1;}
  }
  @keyframes jszr{
    0%{opacity:0;}
    50%{opacity:0.5;}
    100%{opacity:1;}
  }

  .rightCon .animent li a{
    width:100%;
    margin:0 auto;
    display: block;
    position: relative;
    height:100%;
  }
  /* .rightCon .animent h3,.rightCon .animent p{
    position: absolute;
    z-index: 20;
    width:100%;
    text-align:center;
  } */
  .rightCon .animent h3{
    color:#edc108;
    top:23%;
    text-shadow:2px 2px 5px #666;
    font-size:38px;
    font-weight: bold;
  }
  /* .rightCon .animent p{color:#fff;top:33%; font-size:20px;text-shadow:1px 1px 2px #333;} */
  .rightCon .animent li a img{opacity: 0;transition: opacity 1s;margin-top:-10%;}
  .rightCon .animent li a:hover img{opacity:.7;}

  .zrjkLogo{
    background:url(./assets/zrjklogo2020.png) no-repeat;
    width: 203px;
    height: 151px;
    position: absolute;
    right:50%;
    display: inline-block;
    font-size: 0px;
    line-height: 0px;
    bottom:50%;
    z-index: 100;
    margin-right:-101px;
    -webkit-animation:logo 3s .4s ease both;-moz-animation:logo 3s .4s ease both;animation:logo 3s .4s ease both
  }

  @-webkit-keyframes logo{
    0%{opacity:0;transform:scale(1)}
    50%{opacity:1;transform:scale(1)}
    100%{opacity:0;transform:scale(1)}
  }
  @-moz-keyframes logo{
    0%{opacity:0;transform:scale(1)}
    50%{opacity:1;transform:scale(1)}
    100%{opacity:0;transform:scale(1)}
  }
  @keyframes logo{
    0%{opacity:0;transform:scale(1)}
    50%{opacity:1;transform:scale(1)}
    100%{opacity:0;transform:scale(1)}
  }
</style>
