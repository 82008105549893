import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import './assets/index.css'
import 'lib-flexible'

import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'

// import 'swiper/dist/css/swiper.css'
createApp(App).use(store).use(router).use(ElementUI).use(zhLocale).mount("#app");
