
import request from '../utils/request';

var Url = "/api"


const list_url = Url + '/site/show/list' // 列表接口
const selectAllByYear_url = Url + '/site/developmentHistory/selectAllByYear' // 企业概况
const info_url = Url + '/site/ProductOperation/info' // 产业运营
const info_childInfo_url = Url + '/site/ProductOperation/childInfo' // 产业运营--标题点击事件
const info_childSonInfo_url = Url + '/site/ProductOperation/wealthChildInfo' // 产业运营--三级标题点击事件
const ConcernJyklist_url = Url + '/site/ConcernJyk/show/list' // 关注聚益科table列表
const ConcernJykImg_url = Url + '/site/ConcernJyk/getHeadlineImg' // 关注聚益科table列表标题
const GaterInfoJYK_url = Url + '/site/ConcernJyk/getInfoById' // 聚首聚益科菜单接口--详情
const EnterpriseCulture_url = Url + '/site/EnterpriseCulture/info' // 企业文化
const GaterJYK_url = Url + '/site/GaterJYK/info' // 聚首聚益科菜单接口
const Personnel_url = Url + '/site/Personnel/info' // 人才机制接口
const Colourful_url = Url + '/site/Colourful/info' // 多彩聚益科菜单接口
const Colourful_ChildInfo_url = Url + '/site/Colourful/ChildInfo' // 多彩聚益科菜单接口-获取列表
const Colourful_ContentInfo_url = Url + '/site/Colourful/ContentInfo' // 多彩聚益科菜单接口-获取列表
const StaffPresence_url = Url + '/site/StaffPresence/info' // 员工风采菜单接口
const Join_url = Url + '/site/Join/info' // 加入我们菜单接口
const Post_url = Url + '/site/Join/hiring' // 加入我们菜单接口 - 获取招聘岗位列表
const NotesForApplying_url = Url + '/site/Join/NotesForApplying' // 加入我们菜单接口 - 应聘须知接口
const FirsrPage_url = Url + '/site/homePage/getInfo' // 首页接口
const pdfSelect_url = Url + '/site/StaffPresence/PdfSelect' // 聚说select
const pdfInfo_url = Url + '/site/StaffPresence/PdfInfo' // 聚说数据
const firstRlghtUrl = Url + '/site/homePage/getHomePageQrCode' // 首页右侧二维码接口
const templateDataUrl = Url + '/site/pageManage/getPageManageInfo' //模板页面数据接口
/**
 * 列表
 */
export const list_url_list = list_url_datra => {return request({url: list_url,method: 'get',params: list_url_datra})};
/**
 * 企业概况
 */
export const selectAllByYear_list = selectAllByYear_data => {return request({url: selectAllByYear_url,method: 'post',data: selectAllByYear_data})};
/**
 * 产业运营
 */
export const info_list = info_data => {return request({url: info_url,method: 'post',data: info_data})};
/**
 * 产业运营 ---标题点击查询事件
 */
export const info_childInfo_list = info_data => {return request({url: info_childInfo_url,method: 'post',data: info_data})};
/**
 * 产业运营 ---三级标题点击查询事件
 */
export const info_childSonInfo_list = info_data => {return request({url: info_childSonInfo_url,method: 'post',data: info_data})};
/**
 * 关注聚益科table列表
 */
export const ConcernJyklist_list = ConcernJyklist_data => {return request({url: ConcernJyklist_url,method: 'post',data: ConcernJyklist_data})};
/**
 * 关注聚益科table列表 img
 */
export const ConcernJykImg = ConcernJyklist_data => {return request({url: ConcernJykImg_url,method: 'post',data: ConcernJyklist_data})};

/**
 * 关注聚益科table列表 info详情
 */
export const GaterInfoJYK = ConcernJyklist_data => {return request({url: GaterInfoJYK_url,method: 'post',data: ConcernJyklist_data})};
/**
 * 企业文化
 */
export const EnterpriseCulture_list = EnterpriseCulture_data => {return request({url: EnterpriseCulture_url,method: 'post',data: EnterpriseCulture_data})};
/**
 * 聚首聚益科菜单接口
 */
export const GaterJYK_list = GaterJYK_data => {return request({url: GaterJYK_url,method: 'post',data: GaterJYK_data})};
/**
 * 人才机制接口
 */
export const Personnel_list = Personnel_data => {return request({url: Personnel_url,method: 'post',data: Personnel_data})};
/**
 * 多彩聚益科菜单接口
 */
export const Colourful_list = Colourful_data => {return request({url: Colourful_url,method: 'post',data: Colourful_data})};
/**
 * 多彩聚益科菜单接口 - 获取列表
 */
export const Colourful_ChildInfo_list = Colourful_data => {return request({url: Colourful_ChildInfo_url,method: 'post',data: Colourful_data})};
/**
 * 多彩聚益科菜单接口 - 获取列表
 */
export const Colourful_ContentInfo_list = Colourful_data => {return request({url: Colourful_ContentInfo_url,method: 'post',data: Colourful_data})};
/**
 * 员工风采菜单接口
 */
export const StaffPresence_list = StaffPresence_data => {return request({url: StaffPresence_url,method: 'post',data: StaffPresence_data})};
/**
 * 加入我们菜单接口
 */
export const Join_list = Join_data => {return request({url: Join_url,method: 'post',data: Join_data})};

/**
 * 加入我们菜单接口 - 获取招聘岗位
 */
export const getPostList = Join_data => {return request({url: Post_url,method: 'post',data: Join_data})};
/**
 * 加入我们菜单接口 - 应聘须知
 */
export const getNotesForApplying= Join_data => {return request({url: NotesForApplying_url,method: 'post',data: Join_data})};
/* 首页接口 */
export const FirsrPageurl = Join_data => {return request({url: FirsrPage_url,method: 'post',data: Join_data})};
/* 聚说下拉 */
export const pdfSelectUrl = Join_data => {return request({url: pdfSelect_url,method: 'post',data: Join_data})};
/* 聚说详情 */
export const pdfInfoUrl = Join_data => {return request({url: pdfInfo_url,method: 'post',data: Join_data})};

/* 首页右侧二维码 */
export const firstRlUrl = Join_data => {return request({url: firstRlghtUrl,method: 'post',data: Join_data})};

/* 模板页面数据接口 */
export const getTemplateData = Join_data => {return request({url: templateDataUrl,method: 'post',data: Join_data})};
/**
 * 获取图片路径
 */
export const getImgUrl = (url) => {
    if(url && url!= '') {
        if (url.indexOf('test') > -1) {
            return 'http://testattachment.fuguowealth.com/'+ url;
        }
        return 'http://attachment.fuguowealth.com/'+ url;
    }
    return 'http://testattachment.fuguowealth.com/';
};
/**
 * 获取时间格式
 */
export const dateFormat = (time) => {
    return new Date(parseInt(time)).toLocaleString().replace(/:\d{1,2}$/,' ')
}


